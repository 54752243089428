import apis from './apis'
import { BaseService } from './baseService'

export class CommonService extends BaseService {
  static async getListDeliveryNote() {
    try {
      const response = await this.request().get(apis.get_delivery_notes)
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async getAllOrderType() {
    try {
      const response = await this.request().get(apis.get_all_order_type)
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }
}
